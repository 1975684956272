<script lang="ts">
export default { name: 'Maintenance' };
</script>

<script setup lang="ts">
const config = useRuntimeConfig();

enum EIcon {
  LEGERO = 'legero',
  SUPERFIT = 'superfit',
  THINK = 'think',
}

enum EBrand {
  LEGERO = 'legero',
  THINK = 'think',
  SUPERFIT = 'superfit',
}

const isThink = config.BRAND === EBrand.THINK;
const isSuperfit = config.BRAND === EBrand.SUPERFIT;
const isLegero = config.BRAND === EBrand.LEGERO;
</script>

<template>
  <div :class="'theme-' + config.BRAND">
    <div class="maintenance">
      <div class="header">
        <IconSprite
          :name="
            isLegero ? EIcon.LEGERO : isSuperfit ? EIcon.SUPERFIT : EIcon.THINK
          "
          class="logo"
          :class="{
            'logo--legero': isLegero,
            'logo--superfit': isSuperfit,
            'logo--think': isThink,
          }"
        />
      </div>
      <div class="cms">
        <slot />
      </div>
      <div class="minimal-footer">
        <div class="footer-bottom-section">
          <div class="footer-bottom-section-wrapper">
            <div>
              <p class="footer-bottom-section-wrapper_copyright">
                {{ $t('components.footer.copyright') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: center;
  margin: 20px;

  :deep(.logo) {
    height: 42px;
    width: auto;
    display: block;
    color: var(--c-primary);
  }
}

.minimal-footer {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.cms {
  min-height: 500px;
}
</style>
